import React from "react";

const usePrintPage = () => {
  const printPage = () => {
    if (typeof window !== "undefined") {
      return window.print();
    }

    return null;
  };

  return { printPage };
};

export default usePrintPage;
