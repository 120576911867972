// extracted by mini-css-extract-plugin
export var content = "styles-module--content--02346";
export var description = "styles-module--description--fd223";
export var details = "styles-module--details--05699";
export var detailsContainer = "styles-module--detailsContainer--bbee9";
export var documentDescription = "styles-module--documentDescription--124a1";
export var documentLink = "styles-module--documentLink--16a8b";
export var label = "styles-module--label--6565d";
export var link = "styles-module--link--89c1a";
export var linkPrimary = "styles-module--linkPrimary--78306";
export var logo = "styles-module--logo--cdce7";
export var preview = "styles-module--preview--eef59";
export var title = "styles-module--title--6349b";