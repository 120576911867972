import { useEffect, useState } from "react";
import Button from "@/components/elements/Button";

import Checkbox from "@/components/elements/Checkbox";
import Icon from "@/components/elements/Icon";
import { useLanguageContext } from "@/base/context/LanguageContext";
import Heading from "@/components/elements/Heading";
import * as styles from "./styles.module.scss";

export function ProfessionalsOnlyPopup() {
  const [isHealthcareProfessional, setIsHealthcareProfessional] =
    useState(true); // Start with true before we check
  const [isChecked, setIsChecked] = useState(false);
  const { t, language_code } = useLanguageContext();

  function onSubmit() {
    if (!isChecked) return false;

    localStorage.setItem("isHealthcareProfessional", "true");

    setIsHealthcareProfessional(true);
  }

  useEffect(() => {
    const isProfessional =
      localStorage.getItem("isHealthcareProfessional") === "true";

    if (!isProfessional) setIsHealthcareProfessional(false);
  }, []);

  if (isHealthcareProfessional) return null;

  return (
    <div className={styles.popupWrapper}>
      <div className={styles.popup}>
        <button
          onClick={onSubmit}
          className={styles.closeButton}
          aria-label="Close Popup Button"
        >
          <Icon name="close" className={styles.closeButtonIcon} />
        </button>

        <div className={styles.textContent}>
          <Heading
            level={2}
            className={styles.title}
          >{t`professionalsOnlyPopup.title`}</Heading>

          {language_code !== "benl" && (
            <p className={styles.confirmationDemand}>
              {t`professionalsOnlyPopup.confirmationDemandText`}
            </p>
          )}

          <div className={styles.checkboxBlock}>
            <Checkbox
              ariaLabel="Close Popup Button"
              onChange={(e) => setIsChecked(e.target.value)}
              checkmarkClassname={styles.checkmark}
            />

            <p className={styles.confirmationAcceptance}>
              {t`professionalsOnlyPopup.confirmationText`}
            </p>
          </div>
        </div>

        <Button
          variant="primary"
          className={styles.button}
          onClick={onSubmit}
          disabled={!isChecked}
        >
          {t`professionalsOnlyPopup.buttonText`}
        </Button>
      </div>
    </div>
  );
}
