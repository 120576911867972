import {
  CtaWithLogo,
  CtaDocument,
  CtaDocumentPreview,
  CtaMultipleDocuments,
} from "./components";

import * as styles from "./styles.module.scss";

interface CtaWithLogoProps {
  cta:
    | DocumentElement
    | CtaLinkWithLogo
    | CtaDocumentPreview
    | CtaMultipleDocuments;
  type:
    | "element_ctaLink"
    | "element_document"
    | "element_ctaLinkWithLogo"
    | "element_ctaDocumentPreview"
    | "element_multipleDocumentsPreview";
}

function renderCtaElement(item: CtaWithLogoProps["cta"]) {
  switch (item.component) {
    case "element_ctaLinkWithLogo":
      return <CtaWithLogo cta={item} />;
    case "element_ctaDocumentPreview":
      return <CtaDocumentPreview cta={item} />;
    case "element_multipleDocumentsPreview":
      return <CtaMultipleDocuments cta={item} />;
    case "element_document":
      return <CtaDocument cta={item} />;
    default:
      return null;
  }
}

export function CtaBlock({ cta }: CtaWithLogoProps) {
  return <div className={styles.box}>{renderCtaElement(cta)}</div>;
}
