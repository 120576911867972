import { useEffect, useState } from "react";
import LinkComponent from "@/components/elements/LinkComponent";
import { getLocaleFromPathname } from "@/base/Language/getLocaleFromPathname";
import { getPdfSizeInMb } from "@/base/utils/getPDFSizeInMB";
import { useLocation } from "@reach/router";
import * as styles from "./styles.module.scss";

interface CtaMultipleDocumentProps {
  cta: CtaMultipleDocuments;
}

export function CtaMultipleDocuments({ cta }: CtaMultipleDocumentProps) {
  const { label, files, download_label: downloadLabel } = cta;

  const [assets, setAssets] = useState<AssetWithThumbnail[]>([]);

  const location = useLocation();
  const locale = getLocaleFromPathname(location.pathname);

  useEffect(() => {
    (async () => {
      const addPDFFileSize = async (asset: AssetWithThumbnail) => ({
        ...asset,
        PDFFileSize: await getPdfSizeInMb(asset.file.filename),
      });

      const assetsWithPDFFileSize = await Promise.all(
        files.map(addPDFFileSize)
      );

      setAssets(assetsWithPDFFileSize);
    })();
  }, []);

  return (
    <>
      {label && <p className={styles.label}>{label}</p>}

      {assets.map((item) => (
        <div key={item.file.id} className={styles.documentLink}>
          <LinkComponent url={item.file.filename || "/"} type="external">
            <div className={styles.detailsContainer}>
              <img
                src={item.thumbnail.image}
                alt={item.thumbnail.alt_tag ?? ""}
                className={styles.preview}
              />
              <p className={styles.title}>{item.file.title}</p>
              <p className={styles.details}>PDF - {item.PDFFileSize}</p>
            </div>
          </LinkComponent>
        </div>
      ))}

      <LinkComponent
        // TODO: hardcoded path
        url={`/${locale}/resources`}
        type="internal"
        className={styles.link}
        variant="button_outlined"
        with_icon
        ariaLabel="Go to resources"
      >
        {/* TODO: translation */}
        {downloadLabel ?? "Download the file"}
      </LinkComponent>
    </>
  );
}
