import clsx from "clsx";
import dayjs from "dayjs";

import ClipPath from "@/components/elements/ClipPath";
import Heading from "@/components/elements/Heading";
import Image from "@/components/elements/Image";
import LinkComponent from "@/components/elements/LinkComponent";
import Tag from "@/components/elements/Tag";

import useDataSource from "@/base/hooks/useDataSource";
import usePrintPage from "@/base/hooks/usePrintPage";
import Button from "@/components/elements/Button";
import { useLanguageContext } from "@/base/context/LanguageContext";
import * as styles from "./styles.module.scss";

export function UpdateHero({
  su_categories,
  su_date_of_publication,
  su_title,
  su_labels,
  su_hero_img,
}: Pick<
  UpdatePageTemplate,
  | "su_categories"
  | "su_date_of_publication"
  | "su_title"
  | "su_labels"
  | "su_hero_img"
>) {
  const { getNameByValue } = useDataSource<CategoryType>("categories");

  const { t, language_code } = useLanguageContext();

  const { printPage } = usePrintPage();

  return (
    <section className={clsx(styles.sectionHero, "container")}>
      {language_code !== "befr" && (
        <LinkComponent
          url={`/${language_code}/updates`}
          type="internal"
          className={styles.breadcrumb}
        >
          <p className={styles.subtitle}>{t`updates.title`} /</p>
        </LinkComponent>
      )}

      <Heading level={1}>{su_title}</Heading>

      <p className={styles.date}>
        {dayjs(su_date_of_publication).format("DD MMMM YYYY")}
      </p>

      {/* Todo: confirm if this isn't needed */}
      {/* <Button
        className={styles.button}
        icon="download"
        variant="text"
        onClick={printPage}
      >
        Save article as PDF
      </Button> */}

      <div className={styles.tags}>
        {su_labels && su_labels.length > 0 && (
          <div className={styles.types}>
            <p>Type: </p>
            {Array.isArray(su_labels) &&
              su_labels.map((label) => (
                <Tag className={styles.typeTag} type={label} key={label} />
              ))}
          </div>
        )}

        {su_categories.length > 0 && (
          <div className={styles.categories}>
            {su_categories.map((category) => (
              <LinkComponent
                key={category}
                url={`/${language_code}/updates/${category
                  .toLowerCase()
                  .replace(" ", "_")}`}
                type="external"
                className={styles.tag}
              >
                <span>{getNameByValue(category)}</span>
              </LinkComponent>
            ))}
          </div>
        )}
      </div>
    </section>
  );
}
