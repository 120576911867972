// extracted by mini-css-extract-plugin
export var breadcrumb = "styles-module--breadcrumb--df1c5";
export var button = "styles-module--button--55469";
export var categories = "styles-module--categories--62846";
export var date = "styles-module--date--fcdd8";
export var sectionHero = "styles-module--section-hero--ed12c";
export var subtitle = "styles-module--subtitle--4a40c";
export var tag = "styles-module--tag--de050";
export var tags = "styles-module--tags--fbbd6";
export var typeTag = "styles-module--typeTag--2e6c9";
export var types = "styles-module--types--8562c";