import { graphql, PageProps } from "gatsby";

import { lazy } from "react";
import { useInView } from "react-intersection-observer";
import { useStoryblok } from "@/base/lib/storyblok";
import MainLayout from "@/components/layout/Main";

import { LanguageContextProvider } from "@/base/context/LanguageContext";
import { getLocaleFromPathname } from "@/base/Language/getLocaleFromPathname";
import { getLayoutData } from "@/base/helpers/getLayoutData";
import LazyIntersectionObserver from "../elements/LazyIntersectionObserver/LazyIntersectionObserver";
import DocumentReport from "../sections/DocumentReport";
import UpdateContent from "../sections/ScientificUpdateContent";
import UpdateHero from "../sections/ScientificUpdateHero";
import ProfessionalsOnlyPopup from "../sections/ProfessionalsOnlyPopup";

type PageData = {
  headers: StoryblokNodes;
  footers: StoryblokNodes;
  story: StoryblokStory;
};

export function ScientificUpdatePage({ data, location }: PageProps<PageData>) {
  const LazyNewsletter = lazy(() => import("../sections/Newsletter"));

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const { story, footers, headers } = data;

  const locale = getLocaleFromPathname(location.pathname);

  const { header, footer } = getLayoutData(locale, headers, footers);

  const parsedStory = useStoryblok<UpdatePageTemplate>(story, location);

  const {
    content: {
      su_categories,
      su_hero_img,
      su_date_of_publication,
      su_title,
      su_labels,
      su_content,
      su_cta,
      seo,
    },
    full_slug,
  } = parsedStory;

  const documentReport = su_cta?.find(
    (item) => item.component === "element_document"
  ) as DocumentElement;

  return (
    <LanguageContextProvider language_code={locale}>
      <ProfessionalsOnlyPopup />

      <MainLayout
        header={header}
        footer={footer}
        seo={{ ...seo, og_image: seo?.og_image || su_hero_img?.image }}
        type="article"
      >
        <UpdateHero
          su_categories={su_categories}
          su_hero_img={su_hero_img}
          su_date_of_publication={su_date_of_publication}
          su_title={su_title}
          su_labels={su_labels}
        />

        <UpdateContent
          su_title={su_title}
          su_content={su_content}
          su_cta={su_cta}
          postUrl={full_slug}
          su_hero_img={su_hero_img}
        />

        {documentReport && (
          <DocumentReport
            title={documentReport.title}
            description={documentReport.description}
            buttonLabel={documentReport.download_label}
            label={documentReport.label}
            url={documentReport.file.filename}
          />
        )}

        {/* <LazyIntersectionObserver
          Component={LazyNewsletter}
          inView={inView}
          ref={ref}
        /> */}
      </MainLayout>
    </LanguageContextProvider>
  );
}

export const pageQuery = graphql`
  query SuPageQuery($id: String!) {
    story: storyblokEntry(id: { eq: $id }) {
      id
      content
      full_slug
    }
    headers: allStoryblokEntry(
      filter: { field_component: { eq: "template_header" } }
    ) {
      nodes {
        full_slug
        content
      }
    }
    footers: allStoryblokEntry(
      filter: { field_component: { eq: "template_footer" } }
    ) {
      nodes {
        full_slug
        content
      }
    }
  }
`;

export default ScientificUpdatePage;
