// extracted by mini-css-extract-plugin
export var button = "styles-module--button--dc75f";
export var checkboxBlock = "styles-module--checkboxBlock--02d2c";
export var checkmark = "styles-module--checkmark--64276";
export var closeButton = "styles-module--closeButton--88b96";
export var closeButtonIcon = "styles-module--closeButtonIcon--562b8";
export var confirmationAcceptance = "styles-module--confirmationAcceptance--78295";
export var confirmationDemand = "styles-module--confirmationDemand--49027";
export var popup = "styles-module--popup--fe3d1";
export var popupWrapper = "styles-module--popupWrapper--e6673";
export var textContent = "styles-module--textContent--4b473";
export var title = "styles-module--title--94812";