import clsx from "clsx";

import LinkComponent from "@/components/elements/LinkComponent";

import * as styles from "./styles.module.scss";

interface CtaWithLogoProps {
  cta: CtaLinkWithLogo;
}

export function CtaWithLogo({ cta }: CtaWithLogoProps) {
  const { logo, description, link } = cta;

  return (
    <>
      {logo && (
        <img
          src={logo.image}
          alt={logo.alt_tag ?? ""}
          className={styles.logo}
        />
      )}

      {description && <p className={styles.description}>{description}</p>}

      <LinkComponent
        url={link[0]?.url}
        type={link[0]?.type}
        with_icon={link[0]?.with_icon}
        className={clsx(styles.link, styles.linkPrimary)}
        variant="button_primary"
        ariaLabel={link[0].title}
      >
        {link[0].title}
      </LinkComponent>
    </>
  );
}
