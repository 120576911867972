import clsx from "clsx";

import { useEffect, useState } from "react";
import LinkComponent from "@/components/elements/LinkComponent";
import { getPdfSizeInMb } from "@/base/utils/getPDFSizeInMB";
import * as styles from "./styles.module.scss";

interface CtaDocumentPreviewProps {
  cta: CtaDocumentPreview;
}

export function CtaDocumentPreview({ cta }: CtaDocumentPreviewProps) {
  const { label, file, download_label: downloadLabel } = cta;

  const [PDFFileSize, setPDFFileSize] = useState("0 MB");

  const asset = file?.[0];
  const url = asset?.file?.filename;

  useEffect(() => {
    (async () => {
      const fileSize = await getPdfSizeInMb(url);

      setPDFFileSize(fileSize);
    })();
  }, []);

  return (
    <>
      {label && <p className={styles.label}>{label}</p>}

      <div className={styles.documentDescription}>
        <div className={styles.detailsContainer}>
          <img
            src={asset.thumbnail.image}
            alt={asset.thumbnail.alt_tag ?? ""}
            className={styles.preview}
          />
          <p className={styles.title}>{asset.file.title}</p>
          <p className={styles.details}>PDF - {PDFFileSize}</p>
        </div>
      </div>

      <LinkComponent
        url={url || "/"}
        type="external"
        className={clsx(styles.link, styles.linkPrimary)}
        variant="button_primary"
        ariaLabel={asset?.file?.title}
      >
        {/* TODO: translation */}
        {downloadLabel ?? "Download the file"}
      </LinkComponent>
    </>
  );
}
