import { useEffect } from "react";

import Icon from "../Icon";
import * as styles from "./styles.module.scss";

interface CheckboxProps {
  value?: any;
  onChange: (...args: any[]) => void;
  defaultChecked?: boolean;
  ariaLabel?: string;
  checkmarkClassname?: string;
}

export const Checkbox = ({
  value,
  onChange,
  defaultChecked,
  checkmarkClassname,
  ariaLabel,
  ...rest
}: CheckboxProps) => {
  useEffect(() => {
    if (defaultChecked) {
      onChange?.({ target: { value: true } });
    }
  }, [defaultChecked]);

  const toggleCheckbox = (e) => {
    onChange?.({ target: { value: e.target.checked ? true : "" } });
  };

  return (
    <label className={styles.wrapper} aria-label={ariaLabel}>
      <input
        type="checkbox"
        className={styles.input}
        onChange={toggleCheckbox}
        defaultChecked={defaultChecked}
        {...rest}
      />
      <span className={`${styles.checkmark} ${checkmarkClassname}`}>
        <Icon name="check2" />
      </span>
    </label>
  );
};
