export async function getPdfSizeInMb(url: string | undefined) {
  try {
    if (!url) {
      throw new Error("Failed to fetch PDF");
    }

    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Failed to fetch PDF");
    }

    const blob = await response.blob();

    const fileSizeBytes = blob.size;

    const fileSizeMb = fileSizeBytes / (1024 * 1024);

    return fileSizeMb.toFixed(2) + " MB";
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
}
