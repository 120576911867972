import clsx from "clsx";

import LinkComponent from "@/components/elements/LinkComponent";

import * as styles from "./styles.module.scss";

interface CtaDocumentProps {
  cta: DocumentElement;
}

export function CtaDocument({ cta }: CtaDocumentProps) {
  const { label, description, file, download_label: downloadLabel } = cta;

  return (
    <>
      {label && <p className={styles.label}>{label}</p>}

      {description && <p className={styles.description}>{description}</p>}

      <LinkComponent
        url={file.filename || "/"}
        type="external"
        ariaLabel={label || description}
        variant="button_primary"
        className={clsx(styles.link, styles.linkPrimary)}
      >
        {/* TODO: translation */}
        {downloadLabel ?? "Download the file"}
      </LinkComponent>
    </>
  );
}
