import clsx from "clsx";
import Heading from "@/components/elements/Heading";
import Button from "@/components/elements/Button";
import LinkComponent from "@/components/elements/LinkComponent";
import * as styles from "./styles.module.scss";

interface DocumentReportProps {
  title?: string;
  description?: string;
  buttonLabel?: string;
  url?: string;
  label?: string;
}

export function DocumentReport({
  title,
  label,
  description,
  url,
  buttonLabel,
}: DocumentReportProps) {
  return (
    <section className={styles.wrapper}>
      <div className={clsx("container", styles.relativeContainer)}>
        <div className={styles.content}>
          <div className={styles.leafLeft} />
          <div className={styles.leafRight} />
          <div className={styles.contentWrapper}>
            <div className={styles.label}>{label}</div>
            <Heading level={3}>{title}</Heading>
            <div className={styles.description}>{description}</div>
            <Button variant="primary">
              <LinkComponent
                className={styles.downloadLink}
                url={url || "/"}
                type="external"
              >
                {buttonLabel}
              </LinkComponent>
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}
