import clsx from "clsx";
import React from "react";
import Image from "@/components/elements/Image";
import RichText from "@/components/elements/RichText";

import usePrintPage from "@/base/hooks/usePrintPage";
import Button from "@/components/elements/Button";
import { useLanguageContext } from "@/base/context/LanguageContext";
import CtaBlock from "@/components/elements/CtaBlock";
import * as styles from "./styles.module.scss";

interface UpdateContentProps
  extends Pick<
    UpdatePageTemplate,
    "su_content" | "su_cta" | "su_hero_img" | "su_title"
  > {
  postUrl: string;
}

export function UpdateContent({
  su_content,
  su_hero_img,
  su_title,
  su_cta,
}: UpdateContentProps) {
  const { printPage } = usePrintPage();

  const { t } = useLanguageContext();

  return (
    <section className={styles.scientificUpdateContent}>
      <div className={clsx(styles.contentContainer, "container")}>
        <div className={styles.content}>
          {su_hero_img?.image && (
            <div className={styles.imageWrapper}>
              <Image fluid data={su_hero_img} alt={su_title} strategy="eager" />
            </div>
          )}
          <RichText content={su_content} />
          <div className={styles.save}>
            <h3>{t`updates.saveArticle`}</h3>

            <Button icon="download" variant="outlined" onClick={printPage}>
              {t`updates.saveArticle`}
            </Button>
          </div>
        </div>

        {su_cta?.length > 0 && (
          <div className={styles.ctaContainer}>
            {su_cta.map((item, index) => (
              <React.Fragment key={index}>
                <CtaBlock cta={item} type={item.component} />
              </React.Fragment>
            ))}
          </div>
        )}
        {/* <FloatingBox postUrl={postUrl} su_cta={su_cta} /> */}
      </div>
    </section>
  );
}
